import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LuckyNumbersService } from '@scpc/modules/lucky-numbers/services/lucky-numbers.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DrawResult } from '@scpc/modules/lucky-numbers/dto/draw-result';

@Injectable({ providedIn: 'root' })
export class DrawsResultsResolver implements Resolve<{ results?: DrawResult[]; error?: HttpErrorResponse }> {

  constructor(private readonly luckyNumbersService: LuckyNumbersService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<{ results?: DrawResult[]; error?: HttpErrorResponse }> {
    return this.luckyNumbersService.getDrawsResults(route.data.type).pipe(
      map((results: DrawResult[]) => ({ results })),
      catchError((error) => of({ error }))
    );
  }

}
