import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CmsService } from '@scpc/modules/common/services/cms.service';
import { catchError, map } from 'rxjs/operators';
import { LuckyNumbersSettings } from '@scpc/dto/lucky-numbers-settings';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SettingsResolver implements Resolve<{ settings?: LuckyNumbersSettings, error?: HttpErrorResponse }> {

  constructor(private readonly cmsService: CmsService) {
  }

  public resolve(): Observable<{ settings?: LuckyNumbersSettings, error?: HttpErrorResponse }> {
    return this.cmsService.getLuckyNumbersSettings().pipe(
      map((settings: LuckyNumbersSettings) => ({ settings })),
      catchError((error) => of({ error })),
    );
  }

}
