import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Result } from '@scpc/modules/lucky-numbers/dto/result';
import { LuckyNumbersService } from '@scpc/modules/lucky-numbers/services/lucky-numbers.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DrawResultResolver implements Resolve<{ result?: Result; error?: HttpErrorResponse }> {

  constructor(private readonly luckyNumbersService: LuckyNumbersService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<{ result?: Result; error?: HttpErrorResponse }> {
    return this.luckyNumbersService.getDrawResults(route.params.id).pipe(
      map((result: Result) => ({ result })),
      catchError((error) => of({ error }))
    );
  }

}
