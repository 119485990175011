import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LuckyNumbersService } from '@scpc/modules/lucky-numbers/services/lucky-numbers.service';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DrawsResolver implements Resolve<{ draws?: Draw[]; error?: HttpErrorResponse }> {

  constructor(private readonly luckyNumbersService: LuckyNumbersService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<{ draws?: Draw[]; error?: HttpErrorResponse }> {
    if (route.data.ids?.length) {
      return this.getDraws(undefined, route.data.ids);
    } else {
      return this.getDraws(route.data.type);
    }
  }

  private getDraws(type: string, ids?: string[]): Observable<{ draws?: Draw[]; error?: HttpErrorResponse }> {
    return this.luckyNumbersService.getDraws(type, ids).pipe(
      map((draws: Draw[]) => ({ draws })),
      catchError((error) => of({ error }))
    );
  }
}
