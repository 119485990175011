import { Routes } from '@angular/router';
import { PageResolver } from '../common/resolvers/page.resolver';
import { DrawResolver } from '@scpc/modules/lucky-numbers/resolvers/draw.resolver';
import { DrawsResolver } from './resolvers/draws.resolver';
import { DrawResultResolver } from '@scpc/modules/lucky-numbers/resolvers/draw-result.resolver';
import { DrawsResultsResolver } from '@scpc/modules/lucky-numbers/resolvers/draws-results.resolver';
import { SettingsResolver } from '@scpc/modules/lucky-numbers/resolvers/settings.resolver';
import { ProductGuard } from '@scpc/modules/common/guards/product.guard';
import { LuckyNumbersLobbyResolver } from '@scpc/modules/lucky-numbers/resolvers/layout.resolver';

export const ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws-lobby/draws-lobby.component').then(c => c.DrawsLobbyComponent),
    data: {
      product: 'LUCKY_NUMBERS',
    },
    children: [
      {
        path: '',
        canActivate: [ProductGuard],
        data: { product: 'LUCKY_NUMBERS' },
        children: [
          {
            path: '',
            loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws/draws.component').then(c => c.DrawsComponent),
            data: {
              fullWidth: true,
              hasFooter: true,
              type: 'REGULAR',
              product: 'LUCKY_NUMBERS',
            },
            resolve: { seo: PageResolver, draws: DrawsResolver, lobby: LuckyNumbersLobbyResolver },
          },
        ],
      },
      {
        path: 'results',
        canActivate: [ProductGuard],
        loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws-results/draws-results.component').then(c => c.DrawsResultsComponent),
        data: {
          search: true,
          fullWidth: true,
          type: 'REGULAR',
          product: 'LUCKY_NUMBERS',
        },
        resolve: { seo: PageResolver, results: DrawsResultsResolver },
      },
      {
        path: 'rapid',
        canActivate: [ProductGuard],
        data: { product: 'LUCKY_NUMBERS' },
        children: [
          {
            path: '',
            loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws/draws.component').then(c => c.DrawsComponent),
            data: {
              fullWidth: true,
              hasFooter: true,
              type: 'INSTANT',
              product: 'LUCKY_NUMBERS',
            },
            resolve: { seo: PageResolver, draws: DrawsResolver, lobby: LuckyNumbersLobbyResolver },
          },
        ],
      },
      {
        path: 'rapid/results',
        canActivate: [ProductGuard],
        loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws-results/draws-results.component').then(c => c.DrawsResultsComponent),
        data: {
          search: true,
          fullWidth: true,
          type: 'INSTANT',
          product: 'LUCKY_NUMBERS',
        },
        resolve: { seo: PageResolver, results: DrawsResultsResolver },
      },
      {
        path: 'popular',
        canActivate: [ProductGuard],
        data: { product: 'LUCKY_NUMBERS' },
        children: [
          {
            path: '',
            loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws/draws.component').then(c => c.DrawsComponent),
            data: {
              fullWidth: true,
              hasFooter: true,
              type: 'POPULAR',
              product: 'LUCKY_NUMBERS',
            },
            resolve: { seo: PageResolver, draws: DrawsResolver, lobby: LuckyNumbersLobbyResolver },
          },
        ],
      },
      {
        path: 'popular/results',
        canActivate: [ProductGuard],
        loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws-results/draws-results.component').then(c => c.DrawsResultsComponent),
        data: {
          search: true,
          fullWidth: true,
          type: 'POPULAR',
          product: 'LUCKY_NUMBERS',
        },
        resolve: { seo: PageResolver, results: DrawsResultsResolver },
      },
      {
        path: ':id/results',
        canActivate: [ProductGuard],
        loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draw-result/draw-result.component').then(c => c.DrawResultComponent),
        data: {
          search: true,
          fullWidth: true,
          product: 'LUCKY_NUMBERS',
        },
        resolve: { seo: PageResolver, draw: DrawResolver, result: DrawResultResolver },
      },
      {
        path: 'weekly-draws-schedule',
        canActivate: [ProductGuard],
        loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws-schedule-weekly/draws-schedule-weekly.component').then(c => c.DrawsScheduleWeeklyComponent),
        data: {
          search: true,
          fullWidth: true,
          hasFooter: true,
          product: 'LUCKY_NUMBERS',
        },
        resolve: { seo: PageResolver },
      },
      {
        path: 'weekly-draws-schedule/saturday-lotto',
        canActivate: [ProductGuard],
        loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws/draws.component').then(c => c.DrawsComponent),
        data: {
          search: true,
          fullWidth: true,
          hasFooter: true,
          title: 'LN.SATURDAY_LOTTO',
          product: 'LUCKY_NUMBERS',
          ids: [
            'russia_gosloto_7_from_49',
            'uk_49s_lunch',
            'uk_49s_tea',
            'rsa_daily_lotto',
            'russia_pm_gosloto_6_from_45',
          ],
        },
        resolve: { seo: PageResolver, draws: DrawsResolver },
      },
      {
        path: 'weekly-draws-schedule/wednesday-lotto',
        canActivate: [ProductGuard],
        loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draws/draws.component').then(c => c.DrawsComponent),
        data: {
          search: true,
          fullWidth: true,
          hasFooter: true,
          title: 'LN.WEDNESDAY_LOTTO',
          product: 'LUCKY_NUMBERS',
          ids: [
            'russia_gosloto_7_from_49',
            'uk_49s_lunch',
            'uk_49s_tea',
            'rsa_daily_lotto',
            'russia_pm_gosloto_6_from_45',
          ],
        },
        resolve: { seo: PageResolver, draws: DrawsResolver },
      },
      {
        path: ':id',
        canActivate: [ProductGuard],
        data: { product: 'LUCKY_NUMBERS' },
        runGuardsAndResolvers: 'pathParamsChange',
        children: [
          {
            path: '',
            loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draw/draw.component').then(c => c.DrawComponent),
            data: {
              search: true,
              fullWidth: true,
              type: 'M',
            },
            resolve: { seo: PageResolver, draw: DrawResolver },
          },
        ],
      },
      {
        path: ':id/selections',
        canActivate: [ProductGuard],
        data: { product: 'LUCKY_NUMBERS' },
        children: [
          {
            path: '',
            loadComponent: () => import('@scpc/modules/lucky-numbers/pages/draw-selection/draw-selection.component').then(c => c.DrawSelectionComponent),
            data: {
              search: true,
              fullWidth: true,
              type: 'MSF',
            },
            resolve: { seo: PageResolver, draw: DrawResolver, settings: SettingsResolver },
          },
        ],
      },
    ],
  },
];
