import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LuckyNumbersService } from '@scpc/modules/lucky-numbers/services/lucky-numbers.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Draw } from '@scpc/modules/lucky-numbers/dto/draw';

@Injectable({ providedIn: 'root' })
export class DrawResolver implements Resolve<{ draw?: Draw; error?: HttpErrorResponse }> {

  constructor(private readonly luckyNumbersService: LuckyNumbersService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<{ draw?: Draw; error?: HttpErrorResponse }> {
    let o: Observable<Draw>;

    if (route.data.type === 'M') {
      o = this.luckyNumbersService.getDraw(route.params.id, undefined, false, true, false);
    } else if (route.data.type === 'MSF') {
      o = this.luckyNumbersService.getDraw(route.params.id, route.queryParams.category, true, true, false);
    } else {
      o = this.luckyNumbersService.getDraw(route.params.id, undefined, false, false, true);
    }

    return o.pipe(
      map((draw: Draw) => ({ draw })),
      catchError((error) => of({ error }))
    );
  }
}
