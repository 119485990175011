import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LobbyItem } from '@scpc/dto/lobby';
import { CmsService } from '@scpc/modules/common/services/cms.service';

@Injectable({ providedIn: 'root' })
export class LuckyNumbersLobbyResolver implements Resolve<LobbyItem[] | null> {

  constructor(private readonly cmsService: CmsService) {
  }

  public resolve(): Observable<LobbyItem[] | null> {
    return this.cmsService.getLuckyNumbersLobby().pipe(catchError(() => of(null)));
  }

}
